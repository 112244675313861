/**
 * footer
 */
import ResizeWatch from '../utils/ResizeWatch';
import ScrollWatch from '../utils/ScrollWatch';

export default class homeKV {
  /**
   * コンストラクタ
   */
  constructor() {
    // クラス名
    this.name = 'homeKV';
    this.el = document.getElementById('l-home-kv');
    this.slideImgs = this.el.querySelectorAll('.p-kv-slides li');

    this.count = 0;
    this.max = this.slideImgs.length;
    this.preWidth = 0;

    this.isPC = false;
    this.isDevice = null;

    this.sizes = {
      pc: {
        h: 1420,
        w: 2560
      },
      sp: {
        h: 1432,
        w: 828
      }
    };

    this.init();
  }

  /**
   * 表示前
   */
  init() {
    // リサイズでpicture要素のサイズが取得できない場合の対応
    this.bindImgLoad = this.imgLoad.bind(this);
    this.slideImgs.forEach((el, index) => {
      el.style.opacity = 0;
      el.addEventListener('load', this.bindImgLoad);
    });

    // リサイズ
    this.resizeUpdate();
    ResizeWatch.registerObserver(this.name, this);
    if (('onorientationchange' in window)) window.addEventListener('orientationchange', this.resizeUpdate.bind(this));

    this.el.querySelector('.l-home-kv__logo').style.opacity = 0;

    // アニメ開始
    this.start();
  }

  start() {
    TweenMax.fromTo(
      this.slideImgs[this.count], 1.2, {
        opacity: 0,
      }, {
        opacity: 1,
        ease: Power3.easeOut
      });

    TweenMax.fromTo(
      this.slideImgs[this.count], 6.0, {
        scale: 1.1,
      }, {
        scale: 1
      });

    TweenMax.fromTo(
      this.el.querySelector('.l-home-kv__logo'), 1.2, {
        opacity: 0
      }, {
        delay: 0.2,
        opacity: 1,
        ease: Power3.easeOut
      });

    setTimeout(this.slide.bind(this), 4300);
  }

  slide() {
    let next = this.count + 1;
    if (next >= this.max) {
      next = 0;
    } else if (next < 0) {
      next = this.max - 1;
    }

    TweenMax.to(
      this.slideImgs[this.count], 1.6, {
        opacity: 0,
        ease: Power3.easeInOut
      });

    TweenMax.fromTo(
      this.slideImgs[next], 1.6, {
        opacity: 0
      }, {
        opacity: 1,
        ease: Power3.easeOut
      });

    TweenMax.fromTo(
      this.slideImgs[next], 6.0, {
        scale: 1.1,
      }, {
        scale: 1
      });

    this.count = next;

    setTimeout(this.slide.bind(this), 4300);
  }

  /**
   * onloadでpicture要素の画像切り替えタイミングを取得
   */
  imgLoad() {
    this.count++;
    if (this.count === this.max) {
      this.resizeUpdate(true);
      this.count = 0;
    }
  }

  /**
   * リサイズ
   */
  resizeUpdate(isForce) {
    this.isDevice = this.setDevice();
    this.isPC = this.isDevice === 'pc' ? true : false;

    // キービジュアルエリアのサイズ設定
    const winW = window.innerWidth;
    //const winH = window.innerHeight;

    if (!this.isPC && this.preWidth !== winW) {
      this.resize();
    } else if (this.isPC) {
      this.resize();
    } else if (isForce) {
      this.resize();
    }

    this.preWidth = winW;
  }

  resize() {
    // キービジュアルエリアのサイズ設定
    const winW = window.innerWidth;
    let winH = window.innerHeight;
    if (winH < 500) winH = 500;

    TweenMax.set(this.el, {
      height: winH,
      width: winW
    });

    // 画像を表示領域いっぱいに表示する
    for (let i = 0; i < this.max; i++) {
      const imgW = this.sizes[this.isDevice].w;
      const imgH = this.sizes[this.isDevice].h;

      const scale = Math.min(imgW / winW, imgH / winH);

      const frameW = imgW / scale;
      const frameH = imgH / scale;
      const frameX = (winW - frameW) / 2;
      const frameY = (winH - frameH) / 2;

      TweenMax.set(this.slideImgs[i], {position: 'absolute', left: frameX, top: frameY, width: frameW, height: frameH});
    }
  }

  /**
   * MatchMedia（メディアクエリ）による判別
   */
  getMatchMedia() {
    return window.matchMedia('(max-width: 900px)').matches;
  }

  /**
   * 現在のデバイスを判別（ブラウザ幅）
   */
  setDevice() {
    return this.getMatchMedia() ? 'sp' : 'pc';
  }
}
