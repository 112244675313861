'use strict';
import homeVue from './_modules/home/homeVue'
import Gnav from './_modules/common/Gnav'
import Pagetop from './_modules/common/Pagetop'

let main = [];

/**
 * DOMContentLoaded
 */
document.addEventListener('DOMContentLoaded', () => {
  main['homeVue'] = new homeVue();
}, false);

/**
 * window.onload
 */
window.addEventListener('load', () => {
  main['Gnav'] = new Gnav();
  main['Gnav'].start();
  main['Pagetop'] = new Pagetop();

  document.body.style.visibility = 'visible';
}, false);

/**
 * window.unload
 */
window.addEventListener('unload', () => {}, false);

