/**
 * トップページ
 */
import 'es6-promise/auto';
import Vue from 'vue/dist/vue.esm';
import 'lazysizes';
import axios from 'axios';
import homeKV from './homeKV';

export default class homeVue {
  /**
   * コンストラクタ
   */
  constructor() {
    // クラス名
    this.name = 'homeVue';
    this.init();
  }

  /**
   * 表示前
   */
  init() {
    new Vue({
      el: '#vueHome',
      data: {
        photos: [],
        max: 0,
        currentIndex: 0,
        current: {},
        isModalShow: false,
        isPC: true
      },
      computed: {
        frameStyle: function() {
          //return this.message.split('').reverse().join('')
        }
      },
      methods: {
        openModal(index) {
          this.currentIndex = index;
          this.current = this.photos[index];
          this.isModalShow = true;
          this.resize();
        },
        closeModal() {
          this.isModalShow = false;
        },
        next(direction) {
          console.log(direction);
          if (direction) {
            this.currentIndex++;
          } else {
            this.currentIndex--;
          }

          console.log(this.currentIndex);

          if (this.currentIndex < 0) {
            this.currentIndex = this.max - 1;
          } else if (this.currentIndex >= this.max) {
            this.currentIndex = 0;
          }

          this.current = this.photos[this.currentIndex];
        },
        resize() {
          this.isPC = this.setDevice() === 'pc' ? true : false;

          const margin = this.isPC ? 220 : 0;
          const winW = window.innerWidth;
          const winH = window.innerHeight;
          const imgW = this.current.width;
          const imgH = this.current.height;

          const scale = Math.max(imgW / (winW - margin), imgH / (winH - margin));
          const frameW = imgW / scale;
          const frameH = imgH / scale;
          const frameX = (winW - frameW) / 2;
          const frameY = (winH - frameH) / 2;

          this.$refs['img-frame'].style.position = 'absolute';
          this.$refs['img-frame'].style.left = `${frameX}px`;
          this.$refs['img-frame'].style.top = `${frameY}px`;
          this.$refs['img-frame'].style.width = `${frameW}px`;
          this.$refs['img-frame'].style.height = `${frameH}px`;
        },
        /**
         * MatchMedia（メディアクエリ）による判別
         */
        getMatchMedia() {
          return window.matchMedia('(max-width: 900px)').matches;
        },

        /**
         * 現在のデバイスを判別（ブラウザ幅）
         */
        setDevice() {
          return this.getMatchMedia() ? 'sp' : 'pc';
        }
      },
      mounted() {
        axios
          .get('/assets/json/photo.json')
          .then(response => {
            const data = response.data;
            this.photos = data;
            this.max = this.photos.length;
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {});

        this.resize();
        window.addEventListener('resize', this.resize);

        this.$nextTick(function () {
          // ビュー全体がレンダリングされた後にのみ実行されるコード
          new homeKV();
        });
      }
    });

  }
}
