/**
 * ページトップボタン
 */

export default class Pagetop {
  /**
   * コンストラクタ
   */
  constructor() {
    this.name = 'Pagetop'; // クラス名
    this.btn = document.getElementById('p-btn-top');
    this.init();
  }

  /**
   * 初期化
   */
  init() {
    // ボタンイベント
    this.btn.addEventListener('click', this.gotoTop);
  }

  /**
   * ページ先頭へスクロールする
   */
  gotoTop(e) {
    e.preventDefault();
    TweenMax.to(window, 0.5, {scrollTo:{y: 0, autoKill: false, ease: Cubic.easeOut}});
  }
}