//////////////////////////////
// ScrollWatch ///////////////
//////////////////////////////
class _ScrollWatch {

  constructor() {
    // クラス名
    this.name = "ScrollWatch";
    this.init();
  }

  init()
  {
    this.ticking = false;
    this.observers = {};
    this.lastScrollY = this._currentScrollY(); // スクロール値
    window.addEventListener('scroll', this.onScroll.bind(this), {passive: true});
    return this.update();
  }

  _currentScrollY()
  {
    //console.log('スクロール', window.scrollY, document.documentElement.scrollTop, document.body.scrollTop);
    return window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
  }

  registerObserver(id, observer)
  {
    // 連想配列で管理
    return this.observers[id] = observer;
  }

  cancelObserver(id)
  {
    let _ref;
    _ref = this.observers;

    delete _ref[id];

    return _ref;
  }

  callObservers()
  {
    let _i, _ref, _results;
    _ref = this.observers;
    _results = [];

    // 残りのオブザーバーも実行する
    for (_i in _ref)
    {
      _ref[_i].scrollUpdate(this.lastScrollY);
    }

    return _results;
  }

  requestTick()
  {
    if (!this.ticking)
    {
      requestAnimationFrame(this.update.bind(this));
      return this.ticking = true;
    }
  }

  onScroll()
  {
    this.lastScrollY = this._currentScrollY();
    return this.requestTick();
  }

  update()
  {
    this.callObservers();
    return this.ticking = false;
  }
}

const ScrollWatch = new _ScrollWatch();

export default ScrollWatch;
