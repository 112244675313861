//////////////////////////////
// ResizeWatch ///////////////
//////////////////////////////
class _ResizeWatch {

  constructor() {
    // クラス名
    this.name = "ResizeWatch";
    this.init();
  }

  init()
  {
    this.ticking = false;
    this.observers = {};
    window.addEventListener('resize', this.onResize.bind(this), {passive: true});
    return this.update();
  }

  registerObserver(id, observer)
  {
    return this.observers[id] = observer;
  }

  cancelObserver(id)
  {
    let _ref;
    _ref = this.observers;

    // 連想配列に変更
    delete _ref[id];

    return _ref;
  }

  callObservers()
  {
    let _i, _ref, _results;
    _ref = this.observers;
    _results = [];

    // 残りのオブザーバーも実行する
    for (_i in _ref)
    {
      _ref[_i].resizeUpdate();
    }

    return _results;
  }

  requestTick()
  {
    if (!this.ticking)
    {
      requestAnimationFrame(this.update.bind(this));
      return this.ticking = true;
    }
  }

  onResize()
  {
    return this.requestTick();
  }

  update()
  {
    this.callObservers();
    return this.ticking = false;
  }
}

const ResizeWatch = new _ResizeWatch();

export default ResizeWatch;