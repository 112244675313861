/**
 * ヘッダーおよび開閉グロナビの制御
 */
import 'es6-promise/auto';
import Vue from 'vue/dist/vue.esm';
import URI from 'urijs';

export default class Gnav {
  /**
   * コンストラクタ
   */
  constructor() {
    // クラス名
    this.name = 'Gnav';
    this.isOpen = false;
    this.url = location.protocol+'//'+location.hostname;

    const port = location.port;
    if (port != 80 && port != 443) {
      this.url += ':' + port;
    }

    this.init();
  }

  /**
   * 初期化
   */
  init() {
    new Vue({
      el: '#vueHeader',
      data: {
        isGnavShow: false,
        isTop: false
      },
      computed: {
        frameStyle: function() {
          //return this.message.split('').reverse().join('')
        }
      },
      methods: {
        openGnav() {
          this.isGnavShow = true;
          this.resize();
        },
        closeGnav() {
          this.isGnavShow = false;
        },
        resize() {

        },
        gotoAchor(e, anchor) {
          e.preventDefault();

          if (this.isTop) {
            const posY = this.getOffsetTop(document.getElementById(`anchor-${anchor}`)).top;
            TweenMax.to(window, 0.5, {scrollTo:{y: posY, autoKill: false, ease: Cubic.easeOut}});
            this.closeGnav();
          } else {
            location.href = `/?anchor=${anchor}`;
          }
        },
        getOffsetTop(el) {
          let targetY = el.offsetTop;
          let parent = el.offsetParent;
          while (parent && parent != document.body) {
            targetY += parent.offsetTop;
            parent = parent.offsetParent;
          }
          return {top: targetY, parent: parent};
        },
        getPath(urlString) {
          const path = urlString.replace(this.url, '');
          console.log(path.split('/'), location.pathname.split('/'));
        }
      },
      mounted() {

        this.isTop = document.getElementById('l-home-kv') ? true : false;

        window.addEventListener('resize', this.resize);

        this.$nextTick(function () {
          // ビュー全体がレンダリングされた後にのみ実行されるコード
        });
      }
    });
  }

  start() {
    const uri = new URI(location.search);
    const qs = uri.query(true);
    this.gotoAnchor(qs.anchor);
  }

  getOffsetTop(el) {
    let targetY = el.offsetTop;
    let parent = el.offsetParent;
    while (parent && parent != document.body) {
      targetY += parent.offsetTop;
      parent = parent.offsetParent;
    }
    return {top: targetY, parent: parent};
  }

  gotoAnchor(hash) {
    if (hash) {
      const el = document.getElementById(`anchor-${hash}`);
      const posY = this.getOffsetTop(el).top;

      TweenMax.to(window, 0.8, {
        scrollTo: {y: posY, autoKill: false},
        ease: Power3.easeOut
      });
    }
  }
}
